<template>
    <div class="dictionary-page">
        <div class="page-title">
            <div class="ctrl-btns">
                <a-button type="primary" size="small" @click="showAdd"><span class="iconfont icon-piliangtianjia"></span>新增标签</a-button>
            </div>
            <span class="name" style="margin-right:50px;">标签字典</span>
            <a-button-group size="small">
                <a-button v-for="item in dimensions" :key="item.dimensionId" type="primary" :ghost="item.dimensionId!==dimensionId" @click="selectDimension(item.dimensionId)">{{item.dimensionName}}</a-button>
            </a-button-group>
        </div>

        <div class="table-wrap">
            <a-table 
                :columns="columns" 
                :data-source="list" 
                :rowKey="(record)=>record.tagId" 
                :pagination="false" 
                :bordered="false"
                >
                <template slot="actions" slot-scope="text,record">
                    <a-button type="link" @click="edit(record)">编辑</a-button>
                    <a-button type="link" @click="addChild(record)">添加子项</a-button>
                    <a-button type="link" @click="del(record.tagId)">删除</a-button>
                </template>
            </a-table>
        </div>

        <a-modal 
            :title="editKey?'编辑标签':'新增标签'"
            :visible="visbile"
            centered
            :footer="null"
            wrapClassName="edit-cube-win"
            @cancel="cancelAddDimension">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">标签名称</div></a-col>
                <a-col flex="auto">
                    <a-input v-model="name" style="width:100%;" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">父级标签</div></a-col>
                <a-col flex="auto">
                    <a-input v-model="pid" style="width:100%;" :disabled="true" />
                </a-col>
            </a-row>
            <div class="btns">
                <a-button @click="cancelAddDimension">取消</a-button>
                <a-button type="primary" @click="addDimension">确定</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { messageTips } from '../../until/tools';
const columns = [
    {
        title: '序号',
        dataIndex: 'tagId',
        key: 'tagId',
        scopedSlots: { customRender: 'tagId' },
    },
    {
        title: '标签',
        dataIndex: 'tagName',
        key: 'tagName',
    },
    {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
    },
];
export default {
    data() {
        return {
            columns,
            list:[],
            editKey:null,
            visbile:false,
            name:'',
            cubeId:'',
            pid:'-1',
            dimensionId:'',
            dimensions:[],
        }
    },

    created() {
        this.$http.input_get_dimensions().then(res=>{
            this.dimensions = res.list;
            this.dimensionId = res.list[0].dimensionId;
            return res;
        }).then(()=>{
            this.getList();
        });
        
    },
    methods:{

        cancelAddDimension() {
            this.visbile = false;
            this.cubeId = '';
            this.name = '';
            this.pid = '-1';
            this.editKey = null;
        },
        selectDimension(id) {
            this.dimensionId = id;
            this.getList();
        },
        showAdd() {
            this.visbile = true;
        },
        addChild(item) {
            this.pid = item.tagId;
            this.visbile = true;
        },
        getList() {            
            this.$http.getTags({dimensionId:this.dimensionId}).then(res=>{
                if(res&&res.list) {
                    let listTree = this.getTagTree(res.list,'-1');
                    this.list = listTree;
                }
            })
        },
        addDimension() {
            if(this.name==='') {
                messageTips('请输入标签名称',2);
                return false;
            }
            let params = {
                tagName:this.name,
                lang:1,
                dimensionId:this.dimensionId,
            }
            if(this.pid) {
                params.parentId = this.pid;
            }
            let funcName;
            if(this.editKey) {
                params.tagId = this.editKey;
                funcName = 'editTag';
            } else {
                funcName = 'addTag';
            }
            this.$http[funcName](params).then(res=>{
                if(res) {
                    if(res.success) {
                        this.visbile = false;
                        messageTips(res.message,3);
                        this.getList();
                        this.cubeId = '';
                        this.name = '';
                        this.pid = '-1';
                        this.editKey = null;
                    } else {
                        messageTips(res.message,1);
                    }
                }
            });
        },
        edit(item) {
            this.name = item.tagName;
            this.cubeId = item.cubeId;
            this.pid = item.parentId;
            this.editKey = item.tagId;
            this.visbile = true;
        },
        multiDel() {
            this.del(this.selectedRowKeys.join(','));
        },
        del(id) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let params = {
                        tagId:id,
                        dimensionId:that.dimensionId
                    }
                    that.$http.delTag(params).then(res=>{
                        if(res){
                            if(res.success) {
                                that.getList();
                                messageTips(res.message,3);
                            } else {
                                messageTips(res.message,1);
                            }
                        }
                    });
                },
                onCancel() {},
            });
        },
        getTagTree(arr,pid) {
            let result = [];
            arr.forEach(item=>{
                if(item.parentId===pid) {
                    let children = this.getTagTree(arr,item.tagId);
                    if(children.length>0) {
                        item.children = children;
                    }
                    result.push(item);
                }
            });
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/inputs/dictionary.scss';
</style>